import React, { useEffect, useState } from "react";

import logo from "./../../../Images/Gemstone-Report-2.jpg";
import "./print.scss";
import chart from "./../../../Images/diamondCertificateImage1.jpg";
import diamondSize from "./../../../Images/diamondCertificateImagejpg.jpg";
import diamondImage from "./../../../Images/diamondCertificateImage2.jpg";
import line from "./../../../Images/symbols/line.png";
import signLogo from "./../../../Images/Gemstone-Report-2.jpg";
import signature from "./../../../Images/Sgnature.png";
import chip from "./../../../Images/chip.jpg";
import gem from "./../../../Images/LG2100199.png";
import html2canvas from "html2canvas";
import QRCode from "qrcode";
import { Spin, Space } from "antd";
import { dateFunction } from "./../../constant";
import isoCertified from "./../../../Images/isoCeritifiedR.PNG";

function GemStoneCertificate(props) {
	const [capture, setCapture] = useState(false);
	const [qrUrl, setQrUrl] = useState("");

	function pdf() {
		let width = document.querySelector("#capture").clientWidth;
		let height = document.querySelector("#capture").clientHeight;
		html2canvas(document.querySelector("#capture"), { width, height }).then((canvas) => {
			if (document.querySelector("#div2")) {
				document.querySelector("#div2").innerHTML = "";
				document.querySelector("#div2").appendChild(canvas);
				setCapture(true);
			}
		});
	}
	const [PrintArr, setPrintArr] = useState([]);
	useEffect(() => {
		let arr = []
		props.data.map((a, i) => {
			QRCode.toDataURL(
				`https://certificates.ggilab.com/?entries=${a.entryNo
					? a.entryNo
					: a.certificate_number
						? a.certificate_number
						: a.number
							? a.number
							: null}`
			)
				.then((url) => {
					arr.push({
						...a,
						barCodeUrl: url
					})
				})
				.catch((err) => { });

		}
		)
		setPrintArr(arr)


		// With async/await
		const generateQR = async (text) => {
			try {
			} catch (err) { }
		};
		setTimeout(() => {
			document.body.scrollTop = 0;
			document.documentElement.scrollTop = 0;
			// pdf()
		}, 2000);
	}, [props.data]);

	// useEffect(() => {
	// 	setPrintArr();
	// }, []);

	// let keyToSymbolsArr = [
	//     { img: Bruise, name: "Bruise", key: "key_symbol1" },
	//     { img: Cavity, name: "Cavity", key: "key_symbol2" },
	//     { img: Chip, name: "Chip", key: "key_symbol3" },
	//     { img: Cleavage, name: "Cleavage", key: "key_symbol4" },
	//     { img: Cloud, name: "Cloud", key: "key_symbol5" },
	//     { img: Crystal, name: "Crystal", key: "key_symbol5" },
	//     { img: ExtraFacet, name: "Extra Facet", key: "key_symbol6" },
	//     { img: Feather, name: "Feather", key: "key_symbol7" },
	//     { img: GrainCenter, name: "Grain Center", key: "key_symbol8" },
	//     { img: IndentedNatural, name: "Indented Natural", key: "key_symbol9" },
	//     { img: IndentedNatural, name: "Indented Natural", key: "key_symbol10" },
	//     { img: InternalGraining, name: "Internal Graining", key: "key_symbol11" },
	//     { img: Knot, name: "Knot", key: "key_symbol12" },
	//     { img: LaserDrillHole, name: "Laser Drill Hole", key: "key_symbol13" },
	//     { img: Natural, name: "Natural", key: "key_symbol14" },
	//     { img: Needle, name: "Needle", key: "key_symbol15" },
	//     { img: PinPoint, name: "Pin Point", key: "key_symbol16" },
	//     { img: SurfaceGraining, name: "Surface Graining", key: "key_symbol17" },
	//     { img: TwinningWisp, name: "Twinning Wisp", key: "key_symbol18" },
	// ]

	// const [keySymbolArr, setKeySymbolArr] = useState([]);
	// useEffect(() => {
	//     let arr = []
	//     Object.keys(PrintArr).map(
	//         (a, i) => {
	//             if (a.slice(0, 10) === "key_symbol") {
	//                 if (PrintArr[a]) {
	//                     for (let j = 0; j < keyToSymbolsArr.length; j++) {
	//                         if (keyToSymbolsArr[j].key === a) {
	//                             arr.push({
	//                                 ...keyToSymbolsArr[j],
	//                                 url: `${PrintArr[a]}`
	//                             })
	//                         }
	//                     }
	//                 }
	//             }
	//         })
	//     setKeySymbolArr(arr)
	// }, [PrintArr]);

	// useEffect(() => {
	//     console.log(keySymbolArr)
	// }, [keySymbolArr])

	const lineBreak = (text) => {
		let str = text ? text : false;
		console.log(str);
		if (str) {
			let updatedStr = str.split("@@").join(" DouBleBreAk ").split("@").join(" SinGleBreAk ");
			return (
				<p>
					{updatedStr.split(" DouBleBreAk ").length
						? updatedStr.split(" DouBleBreAk ").map((a, i) => {
							return (
								<>
									{a.split(" SinGleBreAk ").length > 1 ? (
										a.split(" SinGleBreAk ").map((b, j) => {
											return (
												<>
													{b}
													<br />
												</>
											);
										})
									) : (
										<>
											{a}
											<br />
											<br />
										</>
									)}
								</>
							);
						})
						: null}
				</p>
			);
		}
	};
	useEffect(() => {
		document.getElementsByTagName('body')[0].classList.add("bigCertificateBody")
	}, [])
	return (
		<>
			{/* <div style={{
                display: capture ? "none" : "block"
            }} className="loader certificateLoader">
                <div className="innerLoader">
                    <Space>
                        <Spin size="large" />
                    </Space>
                    <h2 className="loadingText">Generating Certificate</h2>
                </div>
            </div> */}
			<div
				id="bigCertificate"
				style={{
					display: capture ? "none" : "flex",
					alignItems: "center"
					// justifyContent: "center",
				}}
			>
				<div
					id="capture"
					style={{
						width: "100%",
						margin: "50px 100px",
						display: "flex",
						flexDirection: "row",
						alignContent: "center",
						alignItems: "center",
						justifyContent: "center"
					}}
				>
					{PrintArr.length ?
						PrintArr.map((b, j) => (
							<>
								<div
									style={{
										width: "550px",
										textAlign: "center",
										marginTop: "8px",
										float: "left",
										borderRight: "1px solid darkblue",
										position: "relative"
									}}
									className="linedevClassName"
								>
									<div>
										<img src={logo} style={{ marginRight: "81px", width: "228px" }} />
										<p
											className="MsoNormal"
											style={{
												fontSize: "26px",
												marginLeft: "0px",
												marginLeft: "-66px",
												marginTop: "-4px"
											}}
										>
											<strong style={{ msoBidiFontWeight: "normal" }}>
												<span
													className="logoName"
													style={{
														fontSize: "35px",
														color: "#E00836"
													}}
												>
													<span onClick={() => console.log(b)}>
														Galaxy Gemological Institute <p></p>
													</span>
												</span>
											</strong>
										</p>
										<p
											style={{
												fontSize: "24px",
												color: "#000",
												fontFamily: "cleric",
												textTransform: "uppercase",
												fontWeight: "bold",
												marginRight: "83px",
												fontStyle: "italic"
											}}
										>
											GEMSTONE REPORT
										</p>
										<table
											style={{
												width: "80%",
												textAlign: "left",
												fontFamily: "arial",
												fontSize: "18px",
												marginTop: "10px",
												marginLeft: 0,
												fontWeight: "bold",
												color: "black",
												marginLeft: "25px"
											}}
											align="center"
											cellpadding="0"
											cellspacing="0"
										>
											<tbody>
												<tr style={{ height: "40px", fontSize: "18px" }}>
													<td>
														<strong>
															{dateFunction(
																b.date != "0000-00-00"
																	? b.date
																	: b.entry_date
																		? b.entry_date
																		: b.eptyp_temp,
																true
															)}
														</strong>
													</td>
													<td> </td>
												</tr>
											</tbody>
										</table>
										<table
											style={{
												marginLeft: 0,
												width: "100%",
												textAlign: "left",
												fontFamily: "arial",
												fontSize: "18px",
												marginTop: "10px",
												fontWeight: "bold",
												color: "black",
												marginLeft: "25px"
											}}
											align="center"
											cellpadding="0"
											cellspacing="0"
										>
											<tbody>
												<tr className="tablerow_height">
													<td>No. </td>
													<td>
														<strong>
															{b.entryNo
																? b.entryNo
																: b.certificate_number
																	? b.certificate_number
																	: b.number
																		? b.number
																		: null}
														</strong>
													</td>
												</tr>
												<tr className="tablerow_height">
													<td>Object</td>
													<td>{b.object ? b.object : 'N/A'}</td>
												</tr>

												{/* 
                                            <tr >
                                                <td style={{ width: "40%", height: "45px", fontSize: "15px" }} colspan="2"><strong>Grading Results</strong></td>
                                            </tr> */}

												<tr className="tablerow_height">
													<td>Weight</td>
													<td style={{ textTransform: "uppercase" }}>
														{b.carat_weight ?
															<>
																{b.carat_weight} {b.weight_unit}
															</>
															: 'N/A'}
													</td>
												</tr>

												<tr className="tablerow_height">
													<td>Shape</td>
													<td>{b.shape ? b.shape : 'N/A'}</td>
												</tr>

												<tr className="tablerow_height">
													<td>Cut</td>
													<td>{b.cut ? b.cut : 'N/A'}</td>
												</tr>

												<tr className="tablerow_height">
													<td>
														Measurements{" "}
														{b.measurement_unit === "Milimeter" ? (
															<span style={{ textTransform: "lowercase" }}>(mm)</span>
														) : b.measurement_unit === "Centimeter" ? (
															<span style={{ textTransform: "lowercase" }}>(cm)</span>
														) : (
															"N/A"
														)}
													</td>
													<td>
														{b.measurements || b.measurements2 || b.measurements3 ?
															<>
																{b.measurements} x {b.measurements2} x{" "}
																{b.measurements3}
															</>
															: 'N/A'}
													</td>
												</tr>

												<tr className="tablerow_height">
													<td>Color</td>
													<td>{b.color ? b.color : 'N/A'}</td>
												</tr>

												{b.transparency ? <tr className="fluorescence tablerow_height">
													<td>Transparency</td>
													<td>{b.transparency ? b.transparency : 'N/A'}</td>
												</tr> : ""}

												<tr className="tablerow_height">
													<td colspan="2">
														{" "}
														<span id="comment">Comment</span>
													</td>
												</tr>
											</tbody>
										</table>
										<div style={{ height: "200px", width: "80%", margin: "0px auto 0px 0px" }}>
											<p
												align="justify"
												style={{
													fontSize: "15px",
													fontWeight: "bold",
													marginTop: "-5px",
													marginLeft: "25px",
													textTransform: "none"
												}}
											>
												{b.comment ? lineBreak(b.comment) : 'N/A'}
												<br />{" "}
											</p>
										</div>
									</div>
									<div className="signature_box" style={{ bottom: "40px", width: "480px" }}>
										{" "}
										<p
											align="justify"
											style={{
												fontFamily: "arial",
												fontSize: "11px",
												textAlign: "left",
												marginBottom: "0px",
												padding: "0",
												marginRight: "0px"
											}}
										>
											GGI Galaxy Gemological Institute reports are issued under conditions and
											limitations stated on the reverse side of &nbsp;this report which form an
											integral part of this report.
										</p>
									</div>
								</div>
								<div
									style={{
										width: "624px",
										float: "left",
										marginTop: "33px",
										display: "flex",
										alignItems: "center",
										flexWrap: "wrap",
										justifyContent: "center",
										marginLeft: "22px",
										height: "100vh",
										alignContent: "flex-start"
									}}
								>
									<div
										style={{
											width: "337px",
											height: "150px",
											marginLeft: "109px",
											marginTop: "63px",
											display: "flex",
											alignItems: "center"
										}}
									>
										<img
											src={`${process.env.REACT_APP_BACKEND_URL}media/${b.relatedEntryNo
												? b.product_pic
													? b.product_pic
													: b.product_pic2
														? b.product_pic2
														: b.product_pic3
												: b.product_pic
													? "PropertyPics/" + b.product_pic
													: b.product_pic2
														? "PropertyPics/" + b.product_pic2
														: "PropertyPics/" + b.product_pic3
												}`}
											style={{ marginTop: "37px", height: "160px" }}
											className="product_image"
										/>

										<div
											className="verticaltext"
											align="center"
											style={{
												fontSize: "13px",
												transform: "rotate(270deg)",
												marginBottom: "-32px",
												marginLeft: "-50px"
											}}
										>
											(Approx. Digital Image)
										</div>
									</div>

									<table
										style={{
											width: "77%",
											textAlign: "left",
											fontFamily: "arial",
											fontSize: "18px",
											marginTop: "85px",
											marginBottom: "20px",
											marginRight: "20px",
											borderCollapse: "collapse",
											borderSpacing: "0"
										}}
										align="center"
									>
										<tbody>
											<tr style={{ width: "40%", height: "25px" }}>
												<td
													style={{
														fontWeight: "bold",
														fontSize: "19px",
														fontSize: "22px",
														fontFamily: "cleric",
														fontStyle: "italic",
														textDecoration: "underline",
														border: 'none'
													}}
												>
													Conclusion
												</td>
												{/* <td></td> */}
											</tr>
											{/* <tr>
												<td align="left">
													{" "}
													<hr width="87px" style={{ float: "left", marginTop: "-6px", textDecoration: 'none' }} />
												</td>
											</tr> */}
											{b.species ? (
												<tr style={{ width: "40%", height: "28px", fontWeight: "bold" }}>
													<td
														style={{
															fontSize: "22px",
															fontStyle: "italic",
															fontFamily: "cleric",
															fontWeight: "bold",
															width: "10px",
															width: "20px"
														}}
													>
														Species
													</td>
													<td
														style={{
															fontSize: "22px",
															fontStyle: "italic",
															fontFamily: "cleric",
															fontWeight: " bold"
														}}
													>
														{b.species}
													</td>
												</tr>
											) : null}
											{b.variety ? (
												<tr style={{ width: "40%", fontWeight: "bold" }}>
													<td
														style={{
															fontSize: "22px",
															fontStyle: "italic",
															fontFamily: "cleric",
															fontWeight: "bold"
														}}
													>
														Variety
													</td>
													<td
														style={{
															fontSize: "22px",
															fontStyle: "italic",
															fontFamily: "cleric",
															fontWeight: "bold"
														}}
													>
														{b.variety}
													</td>
												</tr>
											) : null}
											{b.origion_gem ? (
												<tr style={{ width: "40%", fontWeight: "bold" }}>
													<td
														style={{
															fontSize: "22px",
															fontStyle: "italic",
															fontFamily: "cleric",
															fontWeight: "bold"
														}}
													>
														Origin
													</td>
													<td
														style={{
															fontSize: "22px",
															fontStyle: "italic",
															fontFamily: "cleric",
															fontWeight: "bold"
														}}
													>
														{b.origion_gem}
													</td>
												</tr>
											) : null}
										</tbody>
									</table>

									<div className="signature_box" style={{ bottom: "30px", width: "474px" }}>
										<div
											style={{ width: "478px", height: "auto", margin: "0 auto", marginTop: "190px" }}
										>
											<div
												style={{
													width: "154px",
													height: "79px",
													float: "left",
													paddingBottom: "0px",
													marginLeft: "7px",
													marginBottom: "40px"
												}}
											>
												<img src={signLogo} style={{ width: "220px" }} />
											</div>
											<div
												style={{
													width: "200px",
													height: "auto",
													float: "right",
													textAlign: "center",
													paddingBottom: "0px",
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-end",
													justifyContent: "flex-end"
												}}
											>
												<p
													style={{
														color: "#000",
														fontFamily: "arial",
														fontWeight: "bold",
														fontSize: "15px",
														marginTop: "-19px",
														textDecoration: "underline"
													}}
												>
													{" "}
													<img width="160px" src={signature} />
												</p>
												<div
													style={{
														width: "200px",
														textAlign: "center",
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
														alignContent: "center",
														justifyContent: "center"
													}}
												>
													<p
														style={{
															color: "#000",
															fontWeight: "bold",
															fontSize: "20px",
															marginBottom: "-5px",
															marginTop: "-28px",
															fontFamily: "cleric",
															fontStyle: "italic"
														}}
													>
														Mohammad Ali
													</p>
													<label
														style={{
															color: "#000",
															fontFamily: "cleric",
															fontStyle: "italic",
															fontSize: "17px",
															marginTop: "0px",
															fontWeight: "bold"
														}}
													>
														Gemologist, GIA (USA)
													</label>
												</div>
											</div>
										</div>
										<div style={{ clear: "both" }}></div>
										<div className="isoImageDiv" style={{ width: "452px", height: "auto", margin: "0 auto", position: 'relative' }}>
											<div
												style={{
													width: "60px",
													height: "60px",
													float: "left",
													marginTop: "-5px",
													marginLeft: "7px",
													marginBottom: "30px"
												}}
											>
												<img
													style={{
														width: "60px",
														height: "60px"
													}}
													src={chip} className="hollograme" />
											</div>
											<div className="isoImage" style={{

												left: "143px",
												width: "88px",
												position: "absolute",
												bottom: "-66px",
												transform: "translateZ(-1px)",



											}}>
												<img src={isoCertified} style={{ width: "100%" }} />
											</div>
										</div>
										<div style={{ clear: "both" }}></div>
										<div
											style={{
												width: "474px",
												height: "auto",
												margin: "0 auto 0 27px",
												paddingTop: "10px",
												display: "flex",
												justifyContent: "space-around",
												alignItems: 'flex-end'
											}}
										>
											<div
												style={{ width: "90px", height: "60px", float: "left", paddingRight: "50px" }}
											>
												<p
													style={{
														float: "left",
														marginTop: "-37px",
														position: "relative",
														transform: "rotate(270deg)",
														filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
														filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)",
														fontSize: "11px",
														fontWeight: "bold",
														display: "flex",
														flexDirection: "column",
														alignItems: "center",
														marginLeft: "-70px"
													}}
												>
													{" "}
													<span
														style={{
															marginLeft: "25px",
															position: "absolute",
															top: "-8px",
															fontSize: "14px",
															fontWeight: "900"
														}}
													>
														Verification
													</span>{" "}
													<img
														src={b.barCodeUrl}
														alt="QR code"
														style={{ width: "120px", height: "120px", marginLeft: "50px" }}
													/>
												</p>
											</div>
											<div
												style={{
													width: "350px",
													height: "auto",
													float: "left",
													textAlign: "left",
													marginTop: "-16px",
													marginLeft: "20px"
												}}
											>
												<p className="MsoNormal" style={{ marginLeft: "0px", height: "16px" }}>
													<strong style={{ fontWeight: "normal" }}>
														<span
															style={{
																color: "#e00837",
																fontSize: "20px",
																fontFamily: "&quot,Bookman Old Style,quot,&quot,serif&quot",
																fontFamily: "&quot,Adobe Arabic,quot"
															}}
														>
															Galaxy Gemological Institute <p></p>
														</span>
													</strong>
												</p>

												<img
													src={line}
													style={{
														marginTop: "-6px",
														height: "6px",
														width: "310px",
														position: "absolute"
													}}
												/>
												<p
													style={{
														color: "#000",
														fontFamily: "arial",
														fontSize: "12px",
														marginTop: "0px",
														fontWeight: "bold",
														width: "300px"
													}}
												>
													{/* {props.permission.branchCode === "PEW" ? ( */}
													{b.branchCode === "PEW" ? (
														<>
															2nd Floor, Anwaar Flats Shah Qabool Colony#2 Namak Mandi,Peshawar <br />
															Tel: (91) 256-8456,{" "}
															<b>
																<strong
																	style={{
																		fontWeight: "900",
																		fontSize: "12px",
																		textTransform: "lowercase"
																	}}
																>
																	www.ggilab.com
																</strong>

																<b></b>
															</b>
														</>
													) : (
														<>
															Mohan Terrace Building, Sir Jahangir Kothari Trust, Shahrah-e-Iraq,
															Saddar, Karachi <br />
															Tel: +92-21-35216044,{" "}
															<b>
																<strong
																	style={{
																		fontWeight: "900",
																		fontSize: "12px",
																		textTransform: "lowercase"
																	}}
																>
																	www.ggilab.com
																</strong>
																<b></b>
															</b>
														</>
													)}
												</p>
												<b>
													<b></b>
												</b>
											</div>
											<b>
												<b></b>
											</b>
										</div>
										<b>
											<b>
												<div style={{ clear: "both" }}></div>
											</b>
										</b>
									</div>
									<b>
										<b></b>
									</b>
								</div>
							</>
						)
						) : null}
				</div>
			</div>
			<div id="div2"></div>
			<a
				className="printBtnCert"
				href="javascript:window.print()"
				style={{
					backgroundColor: "#4f81bc",
					padding: "10px 20px",
					color: "#fff",
					fontFamily: "arial",
					float: "right",
					marginRight: "20px",
					textDecoration: "none",
					border: "0px solid #385d89"
				}}
				id="printbtn"
			>
				Print
			</a>
		</>
	);
}
export default GemStoneCertificate

